/**
 * @generated SignedSource<<d19938dec0e00e0efaae6e0cc57e5842>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePercentageOff_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useAuctionPricing_item" | "useOriginalListPrice_item">;
  readonly " $fragmentType": "usePercentageOff_item";
};
export type usePercentageOff_item$key = {
  readonly " $data"?: usePercentageOff_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePercentageOff_item">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "pdp",
      "kind": "LocalArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePercentageOff_item",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useOriginalListPrice_item"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useAuctionPricing_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "632e7740495ad3df1d6064b6163606ae";

export default node;
