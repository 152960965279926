import { graphql, useFragment } from 'react-relay';

import { useOriginalListPrice } from './useOriginalListPrice';
import { useAuctionPricing } from './useAuctionPricing';

import { usePercentageOff_item$key } from './__generated__/usePercentageOff_item.graphql';

const itemFragment = graphql`
    fragment usePercentageOff_item on Item
    @argumentDefinitions(page: { type: "PageDisplayEnum", defaultValue: pdp }) {
        ...useOriginalListPrice_item @arguments(page: $page)
        ...useAuctionPricing_item @arguments(page: $page)
    }
`;

export const usePercentageOff = ({
    item: itemRef,
    currency,
}: {
    item: usePercentageOff_item$key;
    currency: string;
}): number | null => {
    const item = useFragment(itemFragment, itemRef);
    const { originalListPrice } = useOriginalListPrice({ item, currency });
    const { startingBidAmount } = useAuctionPricing({ item, currency });

    if (!originalListPrice || !startingBidAmount || startingBidAmount >= originalListPrice) {
        return null;
    }

    const percentageOff = ((originalListPrice - startingBidAmount) / originalListPrice) * 100;

    // Ensure the percentage off is capped at 99% to avoid 100% off.
    return Math.min(Math.round(percentageOff), 99);
};
